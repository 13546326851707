/* @media (max-width: 1690px) {
.att {
right: 4rem;
}
} */

.email-hover {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.email-hover:hover {
white-space: normal;
}

.truncate {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}


