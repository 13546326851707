.instructor-contanier {
    width: 100%;
    margin-top: 1.5rem;
    padding-bottom: 3rem;
    border-bottom:2px solid #FDCF08;
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 10.936), rgba(0, 0, 0, 0.36)), url(../Utils/Assests/about_us.jpg);
    height: 100%;
  }
  
  .row {
    margin-top: 2rem;
  }
  
  
  .instructor-headline{
    text-align: center;
    background-color: #FDCF08;
    color: #ffffff;
    padding: 8px 20px;
    box-shadow: 2px 2px #3b393998;
    cursor: pointer;
  }
  
  .inst-card {
    margin-bottom: 5rem;
    z-index: 10;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  
  .instructor-card-text{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 9rem;
    bottom: 0;
    padding-top: 4rem;
    color: #ffff;
    background: linear-gradient(
      180deg,
      #ffffff00 0%,
      #FDCF08 100%
    );
  }
  
  /* h4:hover, h6 :hover{
    border: #ffff solid 3px;
  } */
  
  .inst-card .Box {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.342);
    width: 18rem;
    height: 24rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -20;
    background-position: center center;
  }
  
  @media only screen and (max-width: 720px) {
  
    .instructor-contanier {
      margin-bottom: none;
    }
  
    .row {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .inst-card {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
    }
  
    .inst-card .Box {
      width: 17rem;
      height: 23rem;
    }
  
  }
  
  @media (orientation : landscape ) {
    .inst-card .Box {
      width: 21rem;
      height: 29rem;
    }
  }
 
  

  
 
  
  .submit-btn {
    padding: 1rem; /* Increased padding */
    font-size: 1.1rem; /* Increased font size */
  }
 
  .close:hover {
    background-color: #ffffff;
  }
  .close-button {
    font-size: 1.5rem;
  }
  
  