.Background{
--bg-image:url(../Utils/Assests/Price.jpg);
--bg-image-opacity: .4;
background-position: center;
background-color: rgba(0, 0, 0, 0.5) ;
position: relative;
isolation: isolate;
width: 100%;

}

.Background::after{
content: '';
opacity: var(--bg-image-opacity);
background-image: var(--bg-image);
background-position: center;
background-size: cover;
position: absolute;
z-index: -1;
inset: 0;

}
